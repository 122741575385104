/* eslint-disable prefer-arrow-callback */
import supportedBrowsersRegex from './supported-browsers-regex';

document.addEventListener(
    'DOMContentLoaded',
    function checkBrowserSupport() {
        const unsupportedBrowser = document.getElementById('unsupported-browser-header');
        if (unsupportedBrowser && !supportedBrowsersRegex.test(navigator.userAgent)) {
            unsupportedBrowser.style.display = 'block';
        }
    },
    false
);
